import React, { useEffect, useState } from "react";
import { headersDataType, metaDataType } from "../types";
import DocumentMeta from 'react-document-meta';

interface SetHeadersProps {
    headerInfo: headersDataType;
}

const SetHeaders: React.FC<SetHeadersProps> = ({ ...props }) => {
    const [tags, setTags] = useState<headersDataType>();
    const metaData: metaDataType = {
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'fotografia,fotografo,fotos,paisajes,naturaleza,youtube,videos,Fotografía de Paisaje,nicodottaphoto,nicolas dotta,video,youtuber,uruguay,montevideo,maldonado,punta del este,aprender fotografia,iso,apertura,punto focal,nikon,canon,fujifilm,sigma,sony,yashica,hasselblad',
            },
        }
    };
    useEffect(() => {
        if (props.headerInfo) setTags(props.headerInfo);
    }, [JSON.stringify(props.headerInfo)]);
    return tags ? <DocumentMeta {...{...tags, ...metaData}} /> : null;
};

export default SetHeaders;

