import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import NavigationMenu from "./NavigationMenu";

import "./MobileMenu.scss";

export interface MobileMenuType {
    show: boolean;
    close: () => void;
}

const MobileMenu: React.FC<MobileMenuType> = ({ ...props }) => {
    if (!props.show) return null;

    return (
        <OutsideClickHandler onOutsideClick={props.close} display="contents" >
            <div className="MobileMenu">
                <div className="section">
                    <div className="CloseMenu" onClick={props.close}>
                        <span className="icon-close" />
                    </div>
                </div>
                <div className="section content">
                    <div className="SidebarContent">
                        <NavigationMenu view="mobile" close={props.close} />
                    </div>
                </div>
            </div>
        </OutsideClickHandler>
    );
}

export default MobileMenu;