import React from "react";
import { useNavigate } from "react-router";
import { SimpleText, translate } from "../components/common/utils";
import LanguageSelector from "./LanguageSelector";

import "./NavigationMenu.scss";

export interface NavigationMenuType {
    view: string;
    close?: () => void;
}
const NavigationMenu: React.FC<NavigationMenuType> = ({ ...props }) => (
    <div className="NavigationMenu">
        <ul className={`format-${props.view}`}>
            {<ListItemNavigation path='/photos' text="menu-fotos" callback={props.close && props.close} />}
            {<ListItemNavigation path='/videos/p-0' text="menu-video" callback={props.close && props.close} />}
            {<ListItemNavigation path='/info' text="menu-information" callback={props.close && props.close} />}
            {<ListItemNavigation path='/newsletter' text="newsletter" callback={props.close && props.close} />}
            {<ListItemNavigation path='/contact' text="menu-contacto" callback={props.close && props.close} />}            
            {props.view === "mobile" && <ListItemNavigation path='/copyright' text="menu-copyright" callback={props.close && props.close} />}
            <LanguageSelector />
        </ul>
    </div >);

export default NavigationMenu;


export interface ListItemNavigationType {
    path: string;
    text: string;
    callback?: () => void;
}

const ListItemNavigation: React.FC<ListItemNavigationType> = ({ ...props }) => {
    const navigate = useNavigate();
    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        props.callback && props.callback();
        navigate(props.path);
        window.scrollTo(0, 0);
    };
    return (
        <li onClick={handleClick}>
            <a href={props.path} onClick={handleClick} title={translate('navigation-text')}>
                <SimpleText langKey={props.text} />
            </a>
        </li>);
}





