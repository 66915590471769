import React, {useState} from "react";
import {
    CollectionTitle,
    translate,
    PargraphText,
    canonical
} from "../../components/common/utils";
import SetHeaders from "../aux/SetHeaders";
import { headersDataType } from "../types";
//
import "./Information.scss";

const Information: React.FC = () => {

    const [metaTags] = useState<headersDataType>({
        title: translate('page-information-title') + " @nicodottaphoto",
        description: translate('page-information-line-1'),
        canonical: canonical('info'),
    });

    const title = <CollectionTitle title={translate("page-information-title")} />;

    return (
        <>
            <SetHeaders headerInfo={metaTags} />
            <div className="Information" >
                {title}
                <div className="image">
                    <div className="image-information" />
                </div>
                <div className="description">
                    <PargraphText text={translate("page-information-line-1")} />
                    <PargraphText text={translate("page-information-line-2")} />
                    <PargraphText text={translate("page-information-line-3")} />
                    <PargraphText text={translate("page-information-line-4")} />
                </div>
            </div>
        </>);
};

export default Information;