import React, { useState } from "react";
import { CollectionTitle, SimpleText, translate, canonical } from "../../components/common/utils";
import { email } from "../../components/common/constants";
import SetHeaders from "../aux/SetHeaders";
import { headersDataType } from "../types";

//
import "./Contact.scss";

const Contact: React.FC = () => {
    const title = <CollectionTitle title={translate("page-contact-title")} />;
    const [metaTags] = useState<headersDataType>({
        title: translate('page-contact-title') + " @nicodottaphoto",
        description: translate('page-contact-text'),
        canonical: canonical('contact'),
    });

    return (
        <>
            <SetHeaders headerInfo={metaTags} />
            <div className="Contact" >
                {title}
                <div className="image">
                    <div className="image-contact" />
                </div>
                <div className="description">
                    <SimpleText text={translate("page-contact-text")} />
                </div>
                <div className="description link">
                    <ul>
                        <li><SimpleText text={email} /></li>
                    </ul>
                </div>
                <div className="description below">
                    <SimpleText langKey="page-contact-socials" />
                    <ul>
                        <li><a href="https://www.instagram.com/nicodottaphoto/" target="_blank" rel="noreferrer">Instagram</a></li>
                        <li><a href="https://www.youtube.com/@nicodottaphoto" target="_blank" rel="noreferrer">Youtube</a></li>
                        <li><a href="https://www.facebook.com/nicodottaphoto" target="_blank" rel="noreferrer">Facebook</a></li>
                        <li><a href="https://twitter.com/nicodottaphoto" target="_blank" rel="noreferrer">Twitter (X)</a></li>
                        <li><a href="https://500px.com/p/nicodottaphoto?view=photos" target="_blank" rel="noreferrer">500px</a></li>
                        <li><a href="https://www.threads.net/@nicodottaphoto" target="_blank" rel="noreferrer">Threads</a></li>
                    </ul>
                </div>
                <div className="description link">
                    <a href="/newsletter" title={translate('newsletter-message')}>
                        <SimpleText langKey="newsletter-message" />
                    </a>
                </div>

            </div>
        </>
    );
};

export default Contact;