import React, { useState } from "react";
import {
    CollectionTitle,
    Navigation,
    SimpleText,
    translate,
    PargraphText,
    canonical
} from "../../components/common/utils";
import SetHeaders from "../aux/SetHeaders";
import { headersDataType } from "../types";
//
import "./NotFound.scss";

const NotFound: React.FC = () => {
    const [metaTags] = useState<headersDataType>({
        title: translate('page-404-0-title'),
        description: translate('page-404-0-description'),
        canonical: canonical(''),
    });
    const title = <CollectionTitle title={translate("page-404-0-title")} />;
    return (
        <>
            <SetHeaders headerInfo={metaTags} />
            <div className="NotFound">
                {title}
                <div className="description">
                    <PargraphText text={translate("page-404-0-description")} />
                </div>
                <div className="description link">
                    <Navigation path='/' >
                        <SimpleText langKey="menu-inicio" />
                    </Navigation>
                </div>
            </div>;
        </>);
};

export default NotFound;