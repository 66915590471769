import React, {useState} from "react";
import {
    CollectionTitle,
    Navigation,
    SimpleText,
    translate,
    PargraphText,
    canonical
} from "../../components/common/utils";
import SetHeaders from "../aux/SetHeaders";
import { headersDataType } from "../types";
//
import "./Copyright.scss";

const Copyright: React.FC = () => {

    const [metaTags] = useState<headersDataType>({
        title: translate('derechos-autor') + " @nicodottaphoto",
        description: translate('page-copy-text'),
        canonical: canonical('copyright'),
    });

    const title = <CollectionTitle title={translate("derechos-autor")} />;

    return (
        <>
        <SetHeaders headerInfo={metaTags} />
        <div className="Copyright" >
            {title}
            <div className="image">
                <div className="image-copyright" />
            </div>
            <div className="description">
                <PargraphText text={translate("page-copy-text")} />
            </div>
            <div className="description link">
                <Navigation path='/contact' >
                    <SimpleText langKey="menu-contacto" />
                </Navigation>
            </div>
        </div>
        </>);
};

export default Copyright;