import React, { useState, useEffect } from "react";
import {
    CollectionTitle,
    SimpleText,
    translate,
    updateUrlPath,
    getPathId,
    canonical
} from "../../components/common/utils";
import { pageLimitVideos } from "../../components/common/constants";
import { contentVideos, contentVideoType } from "../../components/common/sources";
import { VideoPagination } from "../aux/Pagination";
import YTVideo from "../aux/YTVideo";
import ModalGeneric from "../aux/ModalGeneric";
import { headersDataType } from "../types";
import SetHeaders from "../aux/SetHeaders";
//
import "./Videos.scss";

const Videos: React.FC = () => {
    const [itemsPage, setPageItems] = useState<contentVideoType[]>(contentVideos.slice(0, pageLimitVideos));
    const handleSetPageLimit = (value: contentVideoType[]) => setPageItems(value);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedVid, setSelectedVid] = useState<contentVideoType[]>([]);
    const [pagination, setPagination] = useState<number>(0);

    const [metaTags, setMetaTags] = useState<headersDataType>({
        title: translate('meta-video-title'),
        description: translate('meta-video-description'),
        canonical: canonical(`videos/p-${pagination}`),
    });

    const idVideo = getPathId(false, 2);
    const pathNumberPagination = getPathId(true, 2);

    useEffect(() => {
        if (idVideo) {
            const foundId = contentVideos.filter((vid: contentVideoType) => {
                const urlClear = vid.url.split('https://youtu.be/')[1];
                const onlyId = urlClear.split('?')[0];
                return (idVideo === onlyId);
            });
            if (foundId.length == 1){
                setSelectedVid(foundId);
                setShowModal(true);
                const vidId = foundId[0].url.split('https://youtu.be/')[1];
                updateUrlPath('/videos/' + vidId.split('?')[0]);
            } 
        }
    }, [idVideo]);

    const handlePageNumber = (value: number) => {
        setPagination(value);
        const newPath = `/videos/p-${value}`;
        setMetaTags({...metaTags, canonical: canonical(newPath)});
        updateUrlPath(newPath);
    };

    const handleShowModal = (selVid: contentVideoType[]) => () => {
        setSelectedVid(selVid);
        setShowModal(true);
        // window.scrollTo(0, 0);
        // document.body.style.overflow = 'hidden';
        const vidId = selVid[0].url.split('https://youtu.be/')[1];
        updateUrlPath('/videos/' + vidId.split('?')[0]);
    };
    const handleCloseModal = () => {
        setShowModal(false);
        // document.body.style.overflow = 'unset';
        updateUrlPath(`videos/p-${pagination}`);
    };


    const collectionBox = (content: contentVideoType) =>
    (<div className="VideoCollectionBox" onClick={handleShowModal([content])} >
        <div className="VideoCollectionBoxThumb" >
            <div className="youtube-thumb" style={{ backgroundImage: `url(${content.thumbnail})` }} />
        </div>
        <div className="VideoCollectionBox_title" >
            <SimpleText text={content.title} className="titulo" />
        </div>
    </div>);

    const colecctions = (
        <div className="Videos_collections" >
            {itemsPage.map((content: contentVideoType, index: number) =>
                <div className="Videos_collections__item" key={`vid-${index}`}>{collectionBox(content)}</div>)}
        </div>);


    const modal =
        (<ModalGeneric title="Ejemplo" show={showModal} close={handleCloseModal} currentPage={pathNumberPagination as number}>
            <YTVideo video={selectedVid} close={handleCloseModal} />
        </ModalGeneric >);

    return (
        <>
            <SetHeaders headerInfo={metaTags} />
            <div className="Videos" >
                <CollectionTitle title={translate("titulo-videos")} />
                {colecctions}
                <div>
                    <VideoPagination
                        videoList={contentVideos}
                        pageLimit={pageLimitVideos}
                        setItems={handleSetPageLimit || []}
                        getCurrentPageNumber={handlePageNumber}
                        parameterUrl={pathNumberPagination as number}
                    />
                </div>
                {modal}
            </div>
        </>
    );
};

export default Videos;