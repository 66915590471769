import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import {translate } from "../common/utils"; 

import "./ModalGeneric.scss";

export interface ModalType {
    title: string;
    show: boolean;
    close: (value: number) => void;
    children: React.ReactNode;
    currentPage: number;
}

const ModalGeneric: React.FC<ModalType> = ({ ...props }) => {
    if (!props.show) return null;

    const handleCloseDialog = () => props.close(props.currentPage);
    
    const content = (
        <div className="ModalGeneric" >
            <OutsideClickHandler onOutsideClick={handleCloseDialog} display="contents">
                <div className="ModalBox">
                    {props.children}
                    <a onClick={handleCloseDialog} className="ModalClose">{translate('cerrar-ventana')}</a>
                </div>
            </OutsideClickHandler>
        </div>);

    return content;

}
export default ModalGeneric;