import React, { Suspense, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import ScrollToTop from "./layout/ScrollToTop";
import Loading from "./layout/Loading";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Body from "./layout/Body";
import ContextPhotoMenu from "./components/aux/ContextPhotoMenu";
import { blockMouse, pages } from "./components/common/constants";
import Home from "./components/sections/Home";
import NotFound from "./components/aux/NotFound";
import Photos from "./components/sections/Photos"
import Videos from "./components/sections/Videos";
import Copyright from "./components/sections/Copyright";
import Contact from "./components/sections/Contact";
import Information from "./components/sections/Information";
import PhotosCollection from "./components/sections/PhotosCollection";
import RegistrationForm from "./components/sections/RegistrationForm";
//
import "./App.scss";

const App: React.FC = () => {
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);

  const contextMenu = (e: React.MouseEvent) => {
    if (!blockMouse) return;
    e.preventDefault();
    e.stopPropagation();
    setShowContextMenu(true);
  }

  return (
    <Router>
      <ScrollToTop>
        <Suspense fallback={<Loading />}>
          <div className="App" onContextMenu={contextMenu}>
            <Header />
            <Body>
              <Routes>
                <Route path={pages.home} element={<Home />} />
                <Route path={pages.photos} element={<Photos />} />
                <Route path={pages.collection} element={<PhotosCollection />} />
                <Route path={pages.videos} element={<Videos />} />
                <Route path={pages.copyright} element={<Copyright />} />
                <Route path={pages.contact} element={<Contact />} />
                <Route path={pages.info} element={<Information />} />
                <Route path={pages.newsletter} element={<RegistrationForm />} />
                <Route path="/collection/" element={<Navigate to="/photos/" replace={true} />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Body>
            <Footer />
            <ContextPhotoMenu
              show={showContextMenu}
              close={() => setShowContextMenu(false)}
            />
          </div>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

export default App;
