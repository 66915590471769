import React, { ReactNode } from "react";
import NavigationMenu from "./NavigationMenu";
//
import "./Body.scss";

interface BodyProps {
    children: ReactNode;
}

const Body: React.FC<BodyProps> = ({ ...props }) => {
    const mobileNavigation = (
        <div className="Home__linksMobile">
            <NavigationMenu view="desk" />
        </div>
    );

    return (
        <div className="Body" >
            {props.children}
            {mobileNavigation}
        </div>
    );
};
export default Body;

