import React from "react";
import { photoCollectionsType } from "../../components/common/sources";
import { SimpleText, PargraphText, updateUrlPath, trimText } from "../../components/common/utils";
import ShowImage from "../common/ShowImage";

import "./PhotoCard.scss";

export interface PhotoCardType {
    itemsList: photoCollectionsType[];
    callback: (index: number, showNav: boolean) => void;
    collection: string;
}

const PhotoCard: React.FC<PhotoCardType> = ({ ...props }) => {

    const handleClick = (index: number, id: string) => () => {
        updateUrlPath(`/collection${props.collection}/${id}`);
        props.callback(index, true);
    }

    const structure = props.itemsList.map((photoEntry: photoCollectionsType, index: number) => (
        <div className="PhotoEntryContainer" key={`entry-${index}`} onClick={handleClick(index, photoEntry.id)}>
            <div className="entry photoContainer" >
                <ShowImage isThumb={true} path={photoEntry.imgPath} fileName={photoEntry.imgName} />
            </div>
            <div className="entry" ><SimpleText text={photoEntry.title} className="titulo" /></div>
            <div className="entry bottom" ><PargraphText text={trimText(photoEntry.description)} styleClass="description" /></div>
        </div>
    ));
    return <>{structure}</>;
}
export default PhotoCard;