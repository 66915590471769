import React, { useState, useEffect } from "react";
import {
    translate,
    canonical,
} from "../../components/common/utils";
import SetHeaders from "../aux/SetHeaders";
import { headersDataType } from "../types";
import Loading from "../../layout/Loading";
//
import "./RegistrationForm.scss";

const RegistrationForm: React.FC = () => {
    const [metaTags] = useState<headersDataType>({
        title: translate('newsletter') + " @nicodottaphoto",
        description: translate('newsletter'),
        canonical: canonical('newsletter'),
    });

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://nicodottaphoto.ck.page/92cd769898/index.js";
        script.async = true;
        script.setAttribute('data-uid', '92cd769898');
        document.querySelector('.RegistrationForm__placeholder')?.appendChild(script);
        document.querySelector('.Loading')?.remove();

        return () => {
            const element = document.querySelectorAll('.RegistrationForm__placeholder');
            if (element && script && element[0]) element.forEach(e => e.removeChild(script));
        };
    }, []);

    return (
        <>
            <SetHeaders headerInfo={metaTags} />
            <div className="RegistrationForm" >
                <div className="RegistrationForm__placeholder" >
                    <Loading />
                    {/* The script will be injected here by the useEffect hook */}
                </div>
            </div>
        </>);
};

export default RegistrationForm;