import React, { useState, useEffect } from "react";
import { currentLang, textContent } from "./constants";
import Loading from "../../layout/Loading";

export interface ShowImageType {
    isThumb: boolean;
    path: string;
    fileName: string
}
const ShowImage: React.FC<ShowImageType> = ({ ...props }) => {
    const useImage = () => {
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState<string>('');
        const [image, setImage] = useState<string | undefined>(undefined);
        const photoFile = props.isThumb ? 'th_' + props.fileName : props.fileName;

        useEffect(() => {
            const fetchImage = async () => {
                try {
                    const response = await import('../../images/photos/' + props.path + photoFile);
                    setImage(response.default);
                } catch (err) {
                    setError('error on load image');
                } finally {
                    setLoading(false);
                }
            }
            fetchImage();
        }, [props.fileName]);

        return { loading, error, image }
    }

    const { loading, error, image } = useImage();

    if (loading) return <Loading />;
    if (error) return (<>{error}</>);

    return (
        <img src={image}
            alt={textContent['ampliar-foto'][currentLang]}
            title={textContent['ampliar-foto'][currentLang]}
            id={`img-${props.fileName}`}
        />);
}

export default ShowImage;



