import React, { useState } from "react";
import SetHeaders from "../aux/SetHeaders";
import { translate, canonical } from "../common/utils";
import { headersDataType } from "../types";
//
import "./Home.scss";

const Home: React.FC = () => {
    const [metaTags] = useState<headersDataType>({
        title: translate('meta-homepage-title'),
        description: translate('meta-homepage-description'),
        canonical: canonical(''),
    });

    const textDescription = (
        <>
            <h2 className="title-2">{translate('home-transform-title')}</h2>
            <p className="paragraph-1">{translate('home-transform-p1')}</p>
            <p className="paragraph-1">{translate('home-transform-p2')}</p>
            <p className="paragraph-1">{translate('home-transform-p3')}</p>
        </>
    );
    const transformation = (
        <>
            <div className="Home__mobile_text" >
                {textDescription}
            </div>
            <div className="ImageHome image-001" >
                <div className="HomeDescription" >
                    {textDescription}
                </div>
            </div>
        </>
    );

    return (
        <>
            <SetHeaders headerInfo={metaTags} />
            <div className="Home" >
                <div className="Home__presentation">
                    <div className="Home__presentation-image" />
                </div>
                {transformation}
            </div>
        </>);
};

export default Home;


