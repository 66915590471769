import React, { useState, useEffect } from "react";
import {
    pathCollection,
    CollectionTitle,
    updateUrlPath,
    getPathId,
    translate,
    canonical,
} from "../../components/common/utils";
import Loading from "../../layout/Loading";
import { PhotosPagination } from "../aux/Pagination";
import PhotoCard from "./PhotoCard";
import { pageLimitPhotos } from "../../components/common/constants";
import { contentPhotosType, photoCollectionsType } from "../../components/common/sources";
import ModalGeneric from "../aux/ModalGeneric";
import BigPhoto from "./BigPhoto";
import { headersDataType } from "../types";
import SetHeaders from "../aux/SetHeaders";
//
import "./PhotosCollection.scss";

const PhotosCollection: React.FC = () => {
    const collection: contentPhotosType[] = pathCollection();
    if (!collection.length) return <Loading />;

    const dataRecord: contentPhotosType = collection[0];
    const photos: photoCollectionsType[] = dataRecord.photos;
    const [itemsPage, setPageItems] = useState<photoCollectionsType[]>(photos.slice(0, pageLimitPhotos));

    const idPath = getPathId(false, 3);
    const pathNumberPagination = getPathId(true, 3);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState<number>(0);

    const [metaTags, setMetaTags] = useState<headersDataType>({
        title: dataRecord.name + ' - ' + translate('meta-photos-collection-title'),
        description: translate('meta-photos-collection-description') + dataRecord.name,
        canonical: canonical(`collection/${dataRecord.path.replace('/', "")}/p-0`),
    });

    const [showNavigation, setShowNavigation] = useState<boolean>(false);


    // show the modal based on path (idPath)
    useEffect(() => {
        if (idPath) {
            const foundId = photos.map((photo: photoCollectionsType) => {
                return photo.id === idPath;
            }).indexOf(true);
            if (foundId != -1) handleShowModal(foundId, false);
        }
    }, [idPath]);

    // update path on navigation/pagination
    const handlePageNumber = (value: number) => {
        const newPath = `/collection${collection[0].path}/p-${value}`;
        setMetaTags({ ...metaTags, canonical: canonical(`collection/${newPath.replace('/', "")}`) });
        updateUrlPath(newPath);
        setCurrentPage(value);
    };

    const handleShowModal = (selected: number, showNav: boolean) => {
        setShowNavigation(showNav);
        setSelected(selected);
        setShowModal(true);
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';
    };
    const handleCloseModal = (value: number) => {
        const newPath = `/collection${collection[0].path}/p-${value}`;
        setShowModal(false);
        document.body.style.overflow = 'unset';
        updateUrlPath(newPath);
        setCurrentPage(value);
    };

    const modal = 
    (<ModalGeneric title="Modal" show={showModal} close={handleCloseModal} currentPage={currentPage}>
        <BigPhoto
            selectedPhoto={selected}
            collectionItems={itemsPage}
            close={handleCloseModal}
            gallery={dataRecord.path}
            allPhotos={photos}
            showNavigation={showNavigation}
        />
    </ModalGeneric >);
       

    const content = (
        <>
            <SetHeaders headerInfo={metaTags} />
            <div className="PhotosCollection" >
                <CollectionTitle title={dataRecord.name} styles={{ textAlign: 'center' }} />
                <div>
                    <div className="ListContainer">
                        <PhotoCard itemsList={itemsPage} callback={handleShowModal} collection={collection[0].path} />
                    </div>
                </div>
                <div>
                    <PhotosPagination
                        photoList={photos}
                        pageLimit={pageLimitPhotos}
                        setItems={(value: photoCollectionsType[]) => setPageItems(value)}
                        getCurrentPageNumber={handlePageNumber}
                        parameterUrl={pathNumberPagination as number}
                    />
                </div>
            </div>
        </>
    );
    return (<>{content}{modal}</>);
};

export default PhotosCollection;