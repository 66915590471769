import React, { useState, useEffect } from "react";
import { photoCollectionsType, contentVideoType } from "../common/sources";
import { translate } from "../common/utils";


import "./Pagination.scss";

export interface PaginationButtonsLayoutType {
    previousPage: () => void;
    pageNumber: number;
    pageCount: number;
    nextPage: () => void;
}

export const PaginationButtonsLayout: React.FC<PaginationButtonsLayoutType> = ({ ...props }) => {

    let textLegend = `${translate('pagination-pagina')} ${props.pageNumber + 1} `;
    textLegend += `${translate('pagination-pagina-de')} ${props.pageCount}`;
    return (
        <div className="Pagination">
            {props.pageNumber + 1  == 1 
                ? <span className="icon icon-arrow-left disble" />
                : <span onClick={props.previousPage} className="icon icon-arrow-left" />
            
            }
            <span className="text">{textLegend}</span>
            {props.pageNumber + 1 == props.pageCount
                ? <span className="icon icon-arrow-right disble" />
                : <span onClick={props.nextPage} className="icon icon-arrow-right" />
            }
        </div>
    );
};

export interface PhotosPaginationType {
    photoList: photoCollectionsType[];
    pageLimit: number;
    setItems: (value: photoCollectionsType[]) => void;
    getCurrentPageNumber: (value: number) => void;
    parameterUrl: number;
}
export const PhotosPagination: React.FC<PhotosPaginationType> = ({ ...props }) => {
    const [pageNumber, setPageNumber] = useState<number>(props.parameterUrl);
    const pageCount = Math.ceil(props.photoList.length / props.pageLimit);

    useEffect(() => {
        const pageData = (): photoCollectionsType[] => {
            const s = pageNumber * props.pageLimit;
            const e = s + props.pageLimit;
            return props.photoList.slice(s, e);
        };
        const selectedPhotos = pageData();
        props.setItems(selectedPhotos);
    }, [pageNumber]);

    const nextPage = () => {
        setPageNumber(Math.min(pageNumber + 1, pageCount - 1));
        props.getCurrentPageNumber(pageNumber + 1);
        window.scrollTo(0, 0);
    };
    const previousPage = () => {
        window.scrollTo(0, 0);
        setPageNumber(Math.max(pageNumber - 1, 0));
        props.getCurrentPageNumber(pageNumber - 1)
    };

    return <PaginationButtonsLayout
        previousPage={previousPage}
        pageNumber={pageNumber}
        pageCount={pageCount}
        nextPage={nextPage}
    />;
};



export interface VideoPaginationType {
    videoList: contentVideoType[];
    pageLimit: number;
    setItems: (value: contentVideoType[]) => void;
    getCurrentPageNumber: (value: number) => void;
    parameterUrl: number;
}
export const VideoPagination: React.FC<VideoPaginationType> = ({ ...props }) => {
    const [pageNumber, setPageNumber] = useState(props.parameterUrl);
    const pageCount = Math.ceil(props.videoList.length / props.pageLimit);

    useEffect(() => {
        const pageData = (): contentVideoType[] => {
            const s = pageNumber * props.pageLimit;
            const e = s + props.pageLimit;
            return props.videoList.slice(s, e);
        };
        const selectedVideos = pageData();
        props.setItems(selectedVideos);
    }, [pageNumber]);

    const nextPage = () => {
        window.scrollTo(0, 0);
        setPageNumber(Math.min(pageNumber + 1, pageCount - 1));
        props.getCurrentPageNumber(pageNumber + 1);
    };

    const previousPage = () => {
        window.scrollTo(0, 0);
        setPageNumber(Math.max(pageNumber - 1, 0));
        props.getCurrentPageNumber(pageNumber - 1);
    };

    return <PaginationButtonsLayout previousPage={previousPage} pageNumber={pageNumber} pageCount={pageCount} nextPage={nextPage} />;
};