import React, { useEffect, useState } from "react";
import { contentVideoType } from "../../components/common/sources";
import { CollectionTitle, canonical } from "../../components/common/utils";
import { ytChannel } from "../../components/common/constants";
import { headersDataType } from "../types";
import { translate} from "../common/utils";
import SetHeaders from "../aux/SetHeaders";
//
import "./YTVideo.scss";

export interface YTVideoType {
    video: contentVideoType[];
    close: () => void;
}
const YTVideo: React.FC<YTVideoType> = ({ ...props }) => {
    if (!props.video.length) return null;
    const video = props.video[0];
    const videoId = video.url.split('https://youtu.be/')[1];
    const [metaTags, setMetaTags] = useState<headersDataType>();

    useEffect(() => {
        setMetaTags({
            title: video.title + translate('meta-video-yt-title'),
            description: translate('meta-video-yt-description'),
            canonical: canonical(`videos/${videoId.split('?')[0]}`),
        });
    }, [videoId]);


    return (
        <>
            {metaTags && <SetHeaders headerInfo={metaTags} />}

            <div className="YTVideo">
                <div>
                    <div className="VideoContainer">
                        <iframe
                            src={`https://www.youtube.com/embed/${videoId}`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                            className="vd">
                        </iframe>
                    </div>
                </div>
                <div><CollectionTitle title={video.title} /></div>
                <div className="Description">
                    <div className="DescriptionSection">
                        <span className="icon icon-player" />
                        <a href={video.url} target="_blank" rel="noreferrer" >Ver en Youtube</a>
                    </div>
                    <div className="DescriptionSection">
                        <span className="icon icon-profile" />
                        <a href={ytChannel.url} target="_blank" rel="noreferrer" >Visita el canal</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default YTVideo;



// <iframe width="560" height="315" 



