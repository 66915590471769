import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { PargraphText, translate } from "../../components/common/utils";

import "./ContextPhotoMenu.scss";

export interface ContextPhotoMenuType {
    close: () => void;
    show: boolean;
}

const ContextPhotoMenu: React.FC<ContextPhotoMenuType> = ({ ...props }) => {
    if (!props.show) return null;
    const copirightMsg = translate('copiright-atention') + "\n" + translate('autor');
    return (
        <div className="ContextPhotoMenu" onClick={props.close}>
            <OutsideClickHandler onOutsideClick={props.close}>
                <div className="Message" >
                    <PargraphText text={copirightMsg} />
                    <a className="AcceptMessage" onClick={props.close}>{translate('cerrar-ventana')}</a>
                </div>
            </OutsideClickHandler>
        </div >

    );
}


export default ContextPhotoMenu;