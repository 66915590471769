import React, { useState } from "react";
import { Navigation } from "../components/common/utils";
import MobileMenu from "./MobileMenu";
import NavigationMenu from "./NavigationMenu";
//
import "./Header.scss";

const Header: React.FC = () => {
    const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

    const Logo = (
        <div className="Header__logo_title" >
            <h1><Navigation path='/' >@nicodottaphoto</Navigation></h1>
        </div>
    );
    const handleShowMobileMenu = () => {
        setShowMobileMenu(true);
        document.body.style.overflow = 'hidden';
    };
    const handleHideMobileMenu = () => {
        setShowMobileMenu(false);
        document.body.style.overflow = 'unset';
    };

    const LinksSection = (
        <>
            <div className="Header__icon" >
                <a className="icon-burger" onClick={handleShowMobileMenu} />
            </div>
            <div className="Header__links" >
                <NavigationMenu view="desk" />
            </div>
        </>
    );

    return (
        <div className="Header" >
            <div className="Header__section" >{Logo}</div>
            <div className="Header__section" >{LinksSection}</div>
            <MobileMenu show={showMobileMenu} close={handleHideMobileMenu} />
        </div>
    );
};
export default Header;

