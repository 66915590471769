import React, { useState, useEffect } from "react";
import { photoCollectionsType } from "../../components/common/sources";
import {
    CollectionTitle,
    PargraphText,
    updateUrlPath,
    translate,
    canonical
} from "../../components/common/utils";
// import { page500PX } from "../common/constants"
import SetHeaders from "../aux/SetHeaders";
import ShowImage from "../common/ShowImage";
import { headersDataType } from "../types";
//
import "./BigPhoto.scss";

export interface BigPhotoType {
    allPhotos: photoCollectionsType[];
    close: (value: number) => void;
    collectionItems: photoCollectionsType[];
    gallery: string;
    selectedPhoto: number;
    showNavigation: boolean;
}
const BigPhoto: React.FC<BigPhotoType> = ({ ...props }) => {
    const [current, setCurrent] = useState<number>(props.selectedPhoto);
    const [photo, setPhoto] = useState<photoCollectionsType>(
        props.collectionItems[current] ? props.collectionItems[current] : props.allPhotos[current]);
    const [metaTags, setMetaTags] = useState<headersDataType>();

    useEffect(() => {
        setMetaTags({
            title: photo.title + translate('meta-bigphoto-title'),
            description: photo.description + translate('meta-bigphoto-description'),
            canonical: canonical(`collection/${props.gallery.replace('/', "")}/${photo.id}`),
        });
    }, [photo]);

    const handleBack = () => {
        if (current === 0) return;
        let newIndex: number = current;
        if (newIndex < 1) {
            newIndex = 0;
        } else {
            newIndex--;
        }
        setCurrent(newIndex);
        setPhoto(props.collectionItems[newIndex]);
        updateUrlPath('/collection' + props.gallery + '/' + props.collectionItems[newIndex].id)
    };

    const handleForward = () => {
        let newIndex: number = current;
        const total = props.collectionItems.length - 1;
        if (newIndex >= total) {
            return;
        } else {
            newIndex++;
        }
        setCurrent(newIndex);
        setPhoto(props.collectionItems[newIndex]);
        updateUrlPath('/collection' + props.gallery + '/' + props.collectionItems[newIndex].id)
    };


    const navigation = (
        <div className="PhotoNavigation">
            <div className="nav">
                {(current !== 0 && props.showNavigation) && <span onClick={handleBack} className="icon icon-arrow-left" />}
            </div>
            <div className="nav">
                <CollectionTitle title={photo.title} styles={{ textAlign: 'center' }} />
            </div>
            <div className="nav">
                {current < props.collectionItems.length - 1 && props.showNavigation && <span onClick={handleForward} className="icon icon-arrow-right" />}
            </div>
        </div>
    );

    return (
        <>
            {metaTags && <SetHeaders headerInfo={metaTags} />}
            <div className="BigPhoto">
                <div className="PhotoEntry">
                    <ShowImage isThumb={false} path={photo.imgPath} fileName={photo.imgName} />
                    {navigation}
                </div>
                {/* <div><CollectionTitle title={photo.title} styles={{ textAlign: 'center' }} /></div> */}
                <div className="DescriptionCentered"><PargraphText text={photo.description} /></div>
                {/* <div className="DescriptionSection">
                <span className="icon icon-profile" />
                <a href={page500PX.url} target="_blank" rel="noreferrer" >{translate('ver-mas-fotos-500px')}</a>
            </div> */}
            </div>
        </>
    );

}

export default BigPhoto;