import React, { useState } from "react";
import { contentPhotos, contentPhotosType } from "../../components/common/sources";
import { Navigation, CollectionTitle, translate, canonical } from "../../components/common/utils";
import ShowImage from "../common/ShowImage";
import { headersDataType } from "../types";
import SetHeaders from "../aux/SetHeaders";

//
import "./Photos.scss";

const Photos: React.FC = () => {
    const [metaTags] = useState<headersDataType>({
        title: translate('meta-photos-title'),
        description: translate('meta-photos-description'),
        canonical: canonical('photos'),
    });

    const collectionBox = (content: contentPhotosType) =>
    (<div className="CollectionBox" >
        {content.photos.length > 0 && content.photos[0].imgName &&
            (<Navigation path={`/collection${content.path}/p-0`} >
                <ShowImage isThumb={true} path={content.photos[0].imgPath} fileName={content.photos[0].imgName} />
            </Navigation>)
        }
        <div className="CollectionBox_title" >
            <Navigation path={content.path} >{content.name}</Navigation>
        </div>
    </div>);

    return (
        <>
            <SetHeaders headerInfo={metaTags} />
            <div className="Photos" >
                <CollectionTitle title={translate("titulo-fotos")} />
                <div className="Photos_collections" >
                    {contentPhotos.map((content: contentPhotosType, index: number) => (
                        <div className="Photos_collections__item" key={index} >
                            {collectionBox(content)}
                        </div>))}
                </div>
            </div>
        </>);
};
export default Photos;